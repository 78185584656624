
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import agent from 'superagent';
import DatePicker from './datepicker.js';

export class ReservationForm extends React.Component {
    render() {
        const { errors, order, updateOrder, submit } = this.props;
        
        return (
            <div>
                <h1>Reserveren</h1>
                
                <ContactFields
                    order={order}
                    updateOrder={(path, value) => { updateOrder(path, value) }}
                    errors={errors}
                />
                
                <fieldset>
                    <legend>Bezorgadres</legend>
                
                    <div className="form-group">
                        <label>Bedrijfsnaam (optioneel)</label>
                        <input id="company" type="text" className="form-control input-lg"  
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.company}
                            onChange={evt => { updateOrder('company', evt.target.value); }}
                        />
                    </div>
                
                    <div className="form-group">
                        <label>Contactpersoon</label>
                        <input id="addressed_to" type="text" className={"form-control input-lg" + ((errors.addressed_to !== undefined) ? ' is-invalid' : '')} required 
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.addressed_to}
                            onChange={evt => { updateOrder('addressed_to', evt.target.value); }}
                        />
                        {errors.addressed_to !==undefined 
                            && <small className="error-message text-danger">{errors.addressed_to}</small>
                        }
                    </div>
                    
                    <div className="form-group">
                        <label>Straat</label>
                        <input type="text" className={"form-control input-lg" + ((errors.street !== undefined) ? ' is-invalid' : '')} required
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.street}
                            onChange={evt => { updateOrder('street', evt.target.value); }}
                        />
                        {errors.street !==undefined 
                            && <small className="error-message text-danger">{errors.street}</small>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Huisnummer</label>
                                <input id="house_number" type="number" min="0" step="1" className={"integer form-control input-lg" + ((errors.house_number !== undefined) ? ' is-invalid' : '')} required
                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.house_number}
                                    onChange={evt => { updateOrder('house_number', evt.target.value); }}
                                />
                                {errors.house_number !==undefined 
                                    && <small className="error-message text-danger">{errors.house_number}</small>
                                }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Toevoeging</label>
                                <input type="text" className="input-lg form-control"
                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.house_number_suffix}
                                    onChange={evt => { updateOrder('house_number_suffix', evt.target.value); }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Postcode</label>
                                <input id="postal_code" type="text" className={"postcode zipcode-check form-control input-lg" + ((errors.postal_code !== undefined) ? ' is-invalid' : '')}
                                    maxLength={6} min={6} max={6} title="Bijv. 0000AA"
                                    pattern="[1-9][0-9]{3}?[a-zA-Z]{2}" required autoComplete="off"
                                    autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.postal_code}
                                    onChange={evt => {
                                        updateOrder('postal_code', evt.target.value);
                                    }}
                                />
                                {errors.postal_code !==undefined 
                                    && <small className="error-message text-danger">{errors.postal_code}</small>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Woonplaats</label>
                        <input type="text" className={"form-control input-lg" + ((errors.place_of_residence !== undefined) ? ' is-invalid' : '')} required
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.place_of_residence}
                            onChange={evt => { updateOrder('place_of_residence', evt.target.value); }}
                        />
                        {errors.place_of_residence !==undefined 
                            && <small className="error-message text-danger">{errors.place_of_residence}</small>
                        }
                    </div>
                </fieldset>
                
                <fieldset>
                    <legend>Aflevering</legend>
                    
                    <div className="row">
                        <div className="col-6">
                            <div className={"form-group" + ((errors.delivery_start_date !== undefined) ? ' is-invalid' : '')}>
                                <label>Begindatum</label>
                                
                                <DatePicker
                                    locale="nl"
                                    dateFormat="DD-MM-YYYY HH:mm"
                                    timeFormat="HH:mm"
                                    withPortal
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    timeCaption="Tijd"
                                    dropdownMode="select"
                                    selected={order.delivery_start_date === null ? moment().format('DD-MM-YYYY HH:mm').toDate() : moment(order.delivery_start_date, 'DD-MM-YYYY HH:mm').toDate()}
                                    onChange={date => {
                                        updateOrder('delivery_start_date', moment(date).locale('nl'));
                                    }}
                                />
                                
                                {errors.delivery_start_date !==undefined 
                                    && <small className="error-message text-danger">{errors.delivery_start_date}</small>
                                }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={"form-group" + ((errors.delivery_end_date !== undefined) ? ' is-invalid' : '')}>
                                <label>Einddatum</label>

                                <DatePicker
                                    locale="nl"
                                    dateFormat="DD-MM-YYYY HH:mm"
                                    timeFormat="HH:mm"
                                    withPortal
                                    showMonthDropdown
                                    showYearDropdown
                                    showTimeSelect
                                    timeCaption="Tijd"
                                    dropdownMode="select"
                                    selected={order.delivery_end_date === null ? moment().format('DD-MM-YYYY HH:mm').toDate() : moment(order.delivery_end_date, 'DD-MM-YYYY HH:mm').toDate()}
                                    onChange={date => {
                                        updateOrder('delivery_end_date', moment(date).locale('nl'));
                                    }}
                                />
                                
                                {errors.delivery_end_date !==undefined 
                                    && <small className="error-message text-danger">{errors.delivery_end_date}</small>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>Telefoon contactpersoon locatie</label>
                        <input id="delivery-phone" type="text" className={"form-control input-lg" + ((errors.phone_facilitator !== undefined) ? ' is-invalid' : '')} required
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.phone_facilitator}
                            onChange={evt => { updateOrder('phone_facilitator', evt.target.value); }}
                        />
                        {errors.phone_facilitator !==undefined 
                            && <small className="error-message text-danger">{errors.phone_facilitator}</small>
                        }
                    </div>
                    
                    <div className="form-group">
                        <label>Straat</label>
                        <input id="delivery-street" type="text" className={"form-control input-lg" + ((errors.delivery_street !== undefined) ? ' is-invalid' : '')} required
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.delivery_street}
                            onChange={evt => { updateOrder('delivery_street', evt.target.value); }}
                        />
                        {errors.delivery_street !==undefined 
                            && <small className="error-message text-danger">{errors.delivery_street}</small>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Huisnummer</label>
                                <input id="delivery-house-number" type="number" min="0" step="1" className={"form-control integer input-lg" + ((errors.delivery_house_number !== undefined) ? ' is-invalid' : '')} required
                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.delivery_house_number}
                                    onChange={evt => { updateOrder('delivery_house_number', evt.target.value); }}
                                />
                                {errors.delivery_house_number !==undefined 
                                    && <small className="error-message text-danger">{errors.delivery_house_number}</small>
                                }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Toevoeging</label>
                                <input type="text" className="input-lg form-control"
                                    autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.delivery_house_number_suffix}
                                    onChange={evt => { updateOrder('delivery_house_number_suffix', evt.target.value); }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Postcode</label>
                                <input id="delivery-postal-code" type="text" className={"postcode zipcode-check form-control input-lg" + ((errors.delivery_postal_code !== undefined) ? ' is-invalid' : '')}
                                    maxLength={6} min={6} max={6} title="Bijv. 0000AA"
                                    pattern="[1-9][0-9]{3}?[a-zA-Z]{2}" required autoComplete="off"
                                    autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.delivery_postal_code}
                                    onChange={evt => {
                                        updateOrder('delivery_postal_code', evt.target.value);
                                    }}
                                />
                                {errors.delivery_postal_code !==undefined 
                                    && <small className="error-message text-danger">{errors.delivery_postal_code}</small>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Woonplaats</label>
                        <input type="text" className={"form-control input-lg" + ((errors.delivery_place_of_residence !== undefined) ? ' is-invalid' : '')} required
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.delivery_place_of_residence}
                            onChange={evt => { updateOrder('delivery_place_of_residence', evt.target.value); }}
                        />
                        {errors.delivery_place_of_residence !==undefined 
                            && <small className="error-message text-danger">{errors.delivery_place_of_residence}</small>
                        }
                    </div>
                </fieldset>
                
                <fieldset>
                    <legend>Extra opmerkingen</legend>
                    
                    <div className="form-group">
                        <textarea id="remarks" className="form-control input-lg" required 
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.remarks}
                            onChange={evt => { updateOrder('remarks', evt.target.value); }}
                        />
                        <small className="error-message text-danger"/>
                    </div>
                </fieldset>
                
                <button className="btn btn-lg btn-success btn-block mb-2" onClick={() => { submit(); }}>Reserveren</button>
            </div>
        )
    }
}

export class QuestionForm extends React.Component {
    render() {
        const { order, updateOrder, submit } = this.props;
        
        return (
            <div>
                <h1>Vraag stellen</h1>
                
                <ContactFields
                    order={order}
                    updateOrder={(path, value) => { updateOrder(path, value) }}
                />
                
                <fieldset>
                    <legend>Uw vraag</legend>
                    
                    <div className="form-group">
                        <textarea id="remarks" className="form-control input-lg" required 
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.remarks}
                            onChange={evt => { updateOrder('remarks', evt.target.value); }}
                        />
                        <small className="error-message text-danger"/>
                    </div>
                </fieldset>
                
                <button className="btn btn-lg btn-success btn-block" onClick={() => { submit(); }}>Vraag versturen</button>
            </div>
        )
    }
}

export class ContactFields extends React.Component {
    render() {
        const { errors, order, updateOrder, submit } = this.props;
        
        return (
            <div>
                <fieldset>
                    <legend>Uw contactgegevens</legend>
                    
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <div className="control-group">
                                <label>Aanhef</label>
                                <select className={"form-control input-lg" + ((errors.gender !== undefined) ? ' is-invalid' : '')} required autoComplete="off"
                                    value={order.gender}
                                    onChange={evt => { updateOrder('gender', evt.target.value); }}
                                > 
                                    <option value=""/>
                                    <option value="male">De heer</option>
                                    <option value="female">Mevrouw</option>
                                </select>
                                {errors.first_name !==undefined 
                                   && <small className="error-message text-danger">{errors.gender}</small>
                                }
                            </div>
                        </div>
                    </div>
                    
                    <div className="form-group">
                        <label>Naam</label>
                        <input id="first_name" type="text" className={"form-control input-lg" + ((errors.first_name !== undefined) ? ' is-invalid' : '')} required 
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.first_name}
                            onChange={evt => { updateOrder('first_name', evt.target.value); }}
                        />
                        {errors.first_name !==undefined 
                            && <small className="error-message text-danger">{errors.first_name}</small>
                        }
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Voorvoegsel</label>
                                <input id="name_prefix" type="text" className="form-control input-lg" autoComplete="off"
                                    autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                    value={order.name_prefix}
                                    onChange={evt => { updateOrder('name_prefix', evt.target.value); }}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                              <label>Achternaam</label>
                              <input id="last_name" type="text" className={"form-control input-lg" + ((errors.last_name !== undefined) ? ' is-invalid' : '')} required autoComplete="off"
                                  autoCorrect="off" autoCapitalize="off" spellCheck="false"
                                  value={order.last_name}
                                  onChange={evt => { updateOrder('last_name', evt.target.value); }}
                              />
                                {errors.last_name !==undefined 
                                    && <small className="error-message text-danger">{errors.last_name}</small>
                                }
                          </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Telefoonnummer</label>
                        <input type="text" id="phone" className={"telefoon numeric form-control input-lg" + ((errors.phone !== undefined) ? ' is-invalid' : '')} required minLength={10}
                            maxLength={10} autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            pattern="^(?:0|\(?\+31\)?\s?|0031\s?)[1-79](?:[\.\-\s]?\d\d){10}$"
                            value={order.phone}
                            onChange={evt => { updateOrder('phone', evt.target.value); }}
                        />
                        {errors.phone !==undefined 
                            && <small className="error-message text-danger">{errors.phone}</small>
                        }
                    </div>
                    <div className="form-group">
                        <label>E-mailadres</label>
                        <input type="email" id="email" className={"email-address form-control input-lg" + ((errors.email !== undefined) ? ' is-invalid' : '')}
                            pattern="^([a-zA-Z0-9\-\._]+(.[a-zA-Z0-9\-\.])*@[a-zA-Z0-9\-]+(.[a-zA-Z0-9\-\.])*\.[a-zA-Z0-9\-\.]{2,4})?$"
                            autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
                            value={order.email}
                            onChange={evt => { updateOrder('email', evt.target.value); }}
                        />
                        {errors.email !==undefined 
                            && <small className="error-message text-danger">{errors.email}</small>
                        }
                    </div>
                </fieldset>
            </div>  
        );
    }
}
    
export class OrderForm extends React.Component {
    state = {
        submissionState: '',
        errors: {}
    };

    static initializeOrder() {
        return {
            gender: '',
            first_name: '',
            name_prefix: '',
            last_name: '',
            company: '',
            addressed_to: '',
            phone: '',
            email: '',
            street: '',
            house_number: '',
            house_number_suffix: '',
            postal_code: '',
            place_of_residence: '',
            po_box: '',
            country: '',
            remarks: '',
            delivery_street: '',
            delivery_house_number: '',
            delivery_house_number_suffix: '',
            delivery_postal_code: '',
            delivery_place_of_residence: '',
            delivery_start_date: moment().startOf('day').locale('nl'),
            delivery_end_date: moment().endOf('day').locale('nl'),
            phone_facilitator: '',
        };
    }
    
    updateOrder(path, value) {
        const { order } = this.props;
        const updatedOrder = _.set(
            _.cloneDeep(order), path, value
        );
        
        this.props.onChange(updatedOrder);
    }
    
    submit() {
        const { order, ordered_items } = this.props;
        
        // Flatten everything into one big list of POST values
        const fields = {
            ...order,
            ordered_items: JSON.stringify(ordered_items)
        };
        
        // Convert Moment objects to ISO compliant date strings
        fields.delivery_start_date = order.delivery_start_date.toISOString();
        fields.delivery_end_date = order.delivery_end_date.toISOString();
        
        // Use a time-out to give the user an indication something is happening
        window.setTimeout(() => {
            agent.post('/api/orders/')
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .timeout({
                    response: 60000, // Wait X milliseconds for the server to start sending,
                    deadline: 60000, // but wait X milliseconds for the entire request to finish loading
                })
                .send({ formsent: true, ...fields })
                .then((err, response) => {
                    this.props.onSubmit();
                })
                .catch(reason => {
                    this.setState({ submissionState: 'validation_errors' });
                    this.fetchValidationErrors(reason.response);
                    window.scrollTo(0, document.body.scrollHeight); // Scroll to error alert
                })
            ;
        }, 1000);
    }

    fetchValidationErrors(errorResponse) {
        let errors = {};
        const responseBody = JSON.parse(errorResponse.text);
        if (_.has(responseBody, 'errors')) {
            errors = responseBody.errors;
        }
        this.setState({errors: errors});
    }
    
    render() {
        const { order, ordered_items, goToWordChooser, askQuestion } = this.props;
        
        return (
            <div className="checkout-wrapper">
                <OrderSummary 
                    ordered_items={ordered_items}
                    goBack={() => { goToWordChooser(); }}
                />
                
                {
                    (!askQuestion)
                        ?
                            <ReservationForm
                                order={order}
                                updateOrder={(path, value) => { this.updateOrder(path, value) }}
                                submit={() => { this.submit(); }}
                                errors={this.state.errors}
                            />
                        :
                            <QuestionForm
                                order={order}
                                updateOrder={(path, value) => { this.updateOrder(path, value) }}
                                submit={() => { this.submit(); }}
                            />
                }

                {this.state.submissionState === 'validation_errors' 
                    &&
                        <div className="alert alert-warning">
                            Het formulier is niet correct ingevuld. Controleer s.v.p. de gemarkeerde velden.
                        </div>
                }
            </div>
        );
    }
}

export class OrderSummary extends React.Component {
    render() {
        const { ordered_items, goBack } = this.props;
        
        return (
            <div id="order-summary" className="row">
                <div className="back-button-wrapper col-6">
                    <button onClick={() => { goBack(); }}>&laquo; Terug</button>
                </div>
                <div className="col-6">
                    {
                        Object.keys(ordered_items['categories']).map((categoryId) => {
                            const name = ordered_items['categories'][categoryId].name;
                            const letters = ordered_items['categories'][categoryId].all_characters;
                            if (name === 'custom' || letters.length < 1) {
                                return null;
                            }
                            return <p className="selected-letters" key={name}>
                                <small><strong>{name}:</strong> {letters}</small>
                            </p>
                        })
                    }
                </div>
            </div>  
        );
    }
}
