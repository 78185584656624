import React from 'react';

import ReactDatePicker, { registerLocale } from "react-datepicker";
import nl from 'date-fns/locale/nl';
registerLocale("nl", nl);

export default class DatePicker extends React.Component {
    datepickerRef = React.createRef();
    
    render() {
        const props = {
            ...this.props,
            onChange: (...args) => {
                if (this.datepickerRef) {
                    this.datepickerRef.current.input.blur();
                }
                
                if (this.props.onChange) {
                    this.props.onChange(...args);
                }
            },
        };
        
        return (
            <div className="input-group date">
                <ReactDatePicker
                    ref={this.datepickerRef}
                    locale="nl"
                    className="form-control input-lg test"
                    {...props}
                    onClickOutside={() => {
                        if (this.datepickerRef) {
                            this.datepickerRef.current.input.blur();
                        }
                    }}
                />
            </div>
        );
    }
}
