
import _ from 'lodash';
import moment from 'moment';
import match from '@mkrause/match';
import $ from 'jquery';

import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

// Application modules
import * as Shop from './modules/shop.js';
import * as Checkout from './modules/checkout.js';

class App extends React.Component {    
    static pages = {
        wordChooser: 'word_chooser',
        checkout: 'checkout',
        thanks: 'thanks',
    };
    
    state = {
        page: App.pages.wordChooser,
        hideCategoryChooser: false,
        selectedCategoryId: 1,
        hideShoppingCart: false,
        askQuestion: false,
        order: { ...Checkout.OrderForm.initializeOrder() },
        ordered_items: {
            categories: {
                1: {
                    name: '0,50 meter',
                    all_characters: []
                },
                2: {
                    name: '1 meter',
                    all_characters: []
                },
                3: {
                    name: 'Specials',
                    all_characters: []
                },
            }
        },
    };
    
    reset() {
        this.setState(App.initializeOrder());

        localStorage.clear();
        
        this.navigateTo(App.pages.wordChooser);
    }
    
    navigateTo(page) {
        $(document.body).removeClass(`page-${this.state.page}-wrapper`);
        
        this.setState({ page }, () => {
            $(document.body).addClass(`page-${page}-wrapper`);
            
            // Scroll to the top of the page
            window.scrollTo(0, 0);
        });
    }
    
    navigateToWordChooser() {
        this.setState({ hideShoppingCart: false, hideCategoryChooser: false })
        this.navigateTo(App.pages.wordChooser);
    }
    
    navigateToCheckout() {
        this.setState({ hideShoppingCart: true, hideCategoryChooser: true })
        this.navigateTo(App.pages.checkout);
    }
    
    navigateToThanks() {
        this.setState({ hideShoppingCart: true, hideCategoryChooser: true })
        this.navigateTo(App.pages.thanks);
    }
    
    updateLettersToCategory(letters, selectedCategoryId) {
        this.setState(state => {
            const newOrderedItems = _.set(
                _.cloneDeep(state.ordered_items), 
                `categories.${selectedCategoryId}.all_characters`,
                letters
            );
            
            return { ordered_items: newOrderedItems };
        });
    }
    
    renderPage() {
        return match(this.state.page, {
            [App.pages.wordChooser]: () =>
                <div>
                    <Shop.WordChooser 
                        orderedItems={this.state.ordered_items}
                        specialsSymbols={window.dataLayer.specialsSymbols}
                        selectedCategoryId={this.state.selectedCategoryId}
                        onTypingLetters={(letters, selectedCategoryId) => {
                            this.updateLettersToCategory(letters, selectedCategoryId)
                        }}
                        askQuestion={() => {
                            this.setState({ askQuestion: true});
                            this.navigateToCheckout();
                        }}
                    />
                </div>
            ,
            [App.pages.checkout]: () =>
                <div>
                    <Checkout.OrderForm
                        askQuestion={this.state.askQuestion}
                        order={this.state.order}
                        ordered_items={this.state.ordered_items}
                        onChange={order => { this.setState((st) => ({
                            order: {...st.order, ...order }
                        }))}}
                        goToWordChooser={() => { this.navigateToWordChooser(); }}
                        onSubmit={() => { 
                            this.navigateToThanks();
                        }}
                    />
                </div>
            ,
            [App.pages.thanks]: () =>
                <section className="thanks">
                    <h1>Bedankt!</h1>
                    
                    <p>We zullen zo snel mogelijk contact met u opnemen!</p>
                    
                    <p>
                        <a href="https://lumenlichtletters.nl" className="btn btn-success">Ga terug naar de website</a>
                    </p>
                </section>
            ,
        });
    }
    
    getPriceIndications() {
        const ordered_items = this.state.ordered_items;
        const prices = window.dataLayer.prices;
        let totalVAT = 0;
        let totalPrice = 0;
        Object.keys(ordered_items['categories']).map((categoryId) => {
            let characters = ordered_items['categories'][categoryId].all_characters;
            characters.map((character) => {
                character = character.toUpperCase(); // Make sure string comparision doesn't break
                if (character !== ' ') { // Spaces are free!
                    if (_.has(prices, categoryId + '.' + character)) {
                        totalPrice += prices[categoryId][character];
                    } 
                    else {
                        // console.info(character + ' not found in window.dataLayer.prices.' + categoryId);
                        // console.info(prices[categoryId]);
                    }
                }
            });
        });
        
        // Convert amount from cents to euros
        totalPrice = totalPrice / 100;
        
        // Calculate VAT
        totalVAT = totalPrice * 0.21;
        
        return {
            totalVAT: totalVAT.toFixed(2),
            totalPriceExcludingVAT: totalPrice.toFixed(2),
            totalPriceIncludingVAT: (totalPrice + totalVAT).toFixed(2)
        }
    }
    
    render() {
        return (
            <div className={`page-${this.state.page}`}>
                <Shop.CategoryChooser 
                    disabled={this.state.hideCategoryChooser}
                    selectedCategoryId={this.state.selectedCategoryId}
                    onSelectCategory={(categoryId) => {
                        this.setState({ selectedCategoryId: categoryId });
                        this.setState({ hideShoppingCart: false, hideCategoryChooser: false })
                        this.navigateTo(App.pages.wordChooser);
                    }}
                />
                
                <div id="content" className="container" role="main">
                    <div className="content-wrapper row">                
                        <div className="form-wrapper col-12">
                            {this.renderPage()}
                        </div>
                    </div>
                </div>
                
                <Shop.ShoppingCart 
                    disabled={this.state.hideShoppingCart}
                    ordered_items={this.state.ordered_items}
                    priceIndications={this.getPriceIndications()}
                    goToCheckout={() => {
                        this.setState({ askQuestion: false });
                        this.navigateToCheckout(); 
                    }}
                />
            </div>
        );
    }
}

export const init = (mount) => {
    ReactDOM.render(
        <App />,
        mount
    );
};
