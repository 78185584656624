
import React from 'react';
import cx from 'classnames'

export class CategoryChooser extends React.Component {
    render() {
        const { disabled, selectedCategoryId, onSelectCategory } = this.props;
        if (disabled) {
            return null;
        }
        
        return (
            <nav id="category-chooser" role="navigation">
                  <ul className="categories">
                     <li><a onClick={() => { onSelectCategory(1) }} className={cx({'active': (selectedCategoryId === 1)})}>50 CM</a></li>
                     <li><a onClick={() => { onSelectCategory(2) }} className={cx({'active': (selectedCategoryId === 2)})}>1 M</a></li>
                     <li><a onClick={() => { onSelectCategory(3) }} className={cx({'active': (selectedCategoryId === 3)})}>SPECIALS</a></li>
                     <li><a onClick={() => { onSelectCategory('custom') }} className={cx({'active': (selectedCategoryId === 'custom')})}>VRAAG</a></li>
                  </ul>
            </nav>
        );
    }
}

export class SpecialRequests extends React.Component {
    render() {
        return (
            <React.Fragment>
                <section className="special-requests">
                    <h2>Speciale wensen?</h2>
                    
                    <p>
                        <button onClick={() => { this.props.askQuestion(); }}>Laat het ons weten!</button>
                    </p>
                </section>
            </React.Fragment>
        );
    }
}

export class RegularCharacters extends React.Component {
    render() {
        const { orderedItems, selectedCategoryId, onTypingLetters } = this.props;
        const letters = orderedItems['categories'][selectedCategoryId]['all_characters'].join('');
        
        return (
            <React.Fragment>
                <section className={`word-chooser`}>
                    <header>
                        <h2>Light up your event!</h2>
                    </header>
                    
                    <div className="form-group">
                        <input type="text" className="letters form-control input-lg" autoComplete="off" 
                            autoCorrect="off" autoCapitalize="off" spellCheck="false" value={letters}
                            onChange={evt => { onTypingLetters(evt.target.value.split(''), selectedCategoryId); }}
                        />
                        <small id="letters-help" className="form-text text-muted">Welk woord wil je maken?</small>
                    </div>
                </section>
            </React.Fragment>
        );
    }    
}

export class SpecialCharacters extends React.Component {
    render() {
        const { orderedItems, onTypingLetters, selectedCategoryId, specialsSymbols } = this.props;
        let letters = orderedItems['categories'][selectedCategoryId]['all_characters'];
        
        return (
            <React.Fragment>
                <section className={`specials-chooser`}>
                    <header>
                        <h2>Light up your event!</h2>
                    </header>
                    
                    <div className="form-group">
                        <ul className="symbols">
                        {
                            specialsSymbols.map((symbol) => {
                                return <li key={symbol} onClick={(evt) => {
                                    letters.push(symbol);
                                    onTypingLetters(letters, selectedCategoryId); 
                                }}>{symbol}</li>
                            })
                        }
                        </ul>
                        
                        <small id="letters-help" className="form-text text-muted">Welk symbolen wil je kiezen?</small>
                        
                        {letters.length > 0  &&
                            <div className="selected-symbols">
                                <h3>Deze symbolen heb je al gekozen:</h3>
                            
                                <ul className="selected-symbols">
                                {
                                    letters.map((letter, index) => {
                                        return <li key={index}>{letter}<button className="remove-symbol" onClick={(evt) => {
                                            letters.splice(index, 1);
                                            onTypingLetters(letters, selectedCategoryId);
                                        }}>x</button></li>
                                    })
                                }
                                </ul>
                            </div>
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export class WordChooser extends React.Component {
    render() {
        const { orderedItems, selectedCategoryId, onTypingLetters, specialsSymbols } = this.props;
        
        if (selectedCategoryId === 'custom') {
            return (
                <React.Fragment>
                    <SpecialRequests 
                        askQuestion={() => { this.props.askQuestion(); }}
                    />
                </React.Fragment>
            );
        } else if (selectedCategoryId === 1 || selectedCategoryId === 2) { // 0,50m and 1m
            return (
                <React.Fragment>
                    <RegularCharacters
                        orderedItems={orderedItems}
                        selectedCategoryId={selectedCategoryId}
                        onTypingLetters={(letters, selectedCategoryId) => { 
                            onTypingLetters(letters, selectedCategoryId); 
                        }}
                    />
                </React.Fragment>
            );
        } else if (selectedCategoryId === 3) { // Special characters
            return (
                <React.Fragment>
                    <SpecialCharacters
                        orderedItems={orderedItems}
                        selectedCategoryId={selectedCategoryId}
                        specialsSymbols={specialsSymbols}
                        onTypingLetters={(letters, selectedCategoryId) => { 
                            onTypingLetters(letters, selectedCategoryId); 
                        }}
                    />
                </React.Fragment>
            );
        }
        
        return (<div></div>);
    }
}

export class ShoppingCart extends React.Component {
    render() {
        const { disabled, ordered_items, priceIndications, goToCheckout } = this.props;
        if (disabled) {
            return null;
        }
        
        return (
            <footer className="shopping-cart">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <h4>Uw selectie</h4>
                            
                            {
                                Object.keys(ordered_items['categories']).map((categoryId) => {
                                    const name = ordered_items['categories'][categoryId].name;
                                    const letters = ordered_items['categories'][categoryId].all_characters;
                                    if (name === 'custom' || letters.length < 1) {
                                        return null;
                                    }
                                    return <p className="selected-letters" key={name}>
                                        <small><strong>{name}:</strong> {letters}</small>
                                    </p>
                                })
                            }
                        </div>
                        <div id="price-indications" className="col-6">
                            <h4>Prijsindicatie</h4>
                            
                            <p id="price-indications">
                                <small>&euro;{priceIndications.totalPriceIncludingVAT} (<span className="d-none d-md-inline">waarvan </span>&euro;{priceIndications.totalVAT} BTW)</small>
                            </p>
                            
                            <button id="go-to-checkout" onClick={() => { goToCheckout(); }}>Bestellen</button>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
